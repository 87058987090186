import moment from "moment";
import { FaExpandAlt } from "react-icons/fa";
import {
  DefaultAmountFormatter,
  DefaultDateFormatter,
  PercentFormatter
} from "../../Helpers/FormatHelper.js";

const sm = 50;
const md = 75;
const md_2 = 100;

export const PROCUREMENT_STATUS_COLUMNS = [
  {
    field: "",
    headerName: "",
    flex: 1,
    minWidth: 50,
  },
  { field: "SN", headerName: "SN", flex: 2.5, minWidth: 75 },
  { field: "Item", headerName: "Item", flex: 1, minWidth: 400 },
  { field: "Unit", headerName: "Unit", flex: 1, minWidth: 75 },
  {
    field: "PurchaseOrderNumber",
    headerName: "PO No",
    flex: 1,
    minWidth: 150,
  },
  { field: "Activity", headerName: "Activity", flex: 1, minWidth: 150 },
  { field: "Procurer", headerName: "Procurer", flex: 1, minWidth: 150 },
  {
    field: "FundingSource",
    headerName: "Funding Source",
    flex: 1,
    minWidth: 200,
  },
  {
    field: "POType",
    headerName: "PO Type",
    flex: 1,
    minWidth: 100,
  },
  {
    field: "RequestLetterNumber",
    headerName: "Request Letter No",
    flex: 1,
    minWidth: 250,
  },
  {
    field: "Requestor",
    headerName: "Requester",
    flex: 1,
    minWidth: 250,
  },
  {
    field: "RequestDate",
    headerName: "Request Date",
    flex: 1,
    minWidth: 200,
    type: "date",
    valueFormatter: (params) => {
      return DefaultDateFormatter(params?.value);
    },
  },
  {
    field: "TenderNumber",
    headerName: "Tender Number",
    flex: 1,
    minWidth: 250,
  },
  {
    field: "RequestedQuantity",
    headerName: "Requested Quantity",
    flex: 1,
    minWidth: 150,
    type: "number",
  },
  {
    field: "POQuantity",
    headerName: "PO Quantity",
    flex: 1,
    minWidth: 150,
    type: "number",
  },
  {
    field: "InvoicedQuantity",
    headerName: "Invoiced Quantity",
    flex: 1,
    minWidth: 150,
    type: "number",
  },
  { field: "Status", headerName: "Status", flex: 1, minWidth: 250 },
  {
    field: "DetailStatus",
    headerName: "Detail Status",
    flex: 1,
    minWidth: 250,
    filterParam: "IsAggregate",
    visibleValues: [ false ]
  },
];

export const STOCK_STATUS_COLUMNS = [
  { field: "FullItemName", headerName: "Item", flex: 2.5, minWidth: 400 },
  { field: "Unit", headerName: "Unit", flex: 1, minWidth: 75 },
  { field: "ModeCode", headerName: "Mode", flex: 1, minWidth: 75 },
  {
    field: "SOH",
    headerName: "SOH",
    flex: 1,
    minWidth: 75,
    type: "number",
    colorCodeStatusCol: "SS",
  },
  {
    field: "L6Month",
    headerName: "Potential to Expire Quantity",
    flex: 1,
    minWidth: 150,
    type: "number",
  },
  { field: "AMC", headerName: "AMC", flex: 1, minWidth: 150, type: "number" },
  { field: "MOS", headerName: "MOS", flex: 1, minWidth: 150, type: "number" },
  {
    field: "Ordered",
    headerName: "Ordered",
    flex: 1,
    minWidth: 150,
    type: "number",
  },
  { field: "GIT", headerName: "GIT", flex: 1, minWidth: 150, type: "number" },
  {
    field: "Expired",
    headerName: "Expired",
    flex: 1,
    minWidth: 150,
    type: "number",
  },
  {
    field: "Damaged",
    headerName: "Damaged",
    flex: 1,
    minWidth: 150,
    type: "number",
  },
];

export const STOCK_STATUS_FACILITY_COLUMNS = [
  { field: "item", headerName: "Item", flex: 2.5, minWidth: 400 },
  { field: "unit", headerName: "Unit", flex: 1, minWidth: 75 },
  {
    field: "commodityType",
    headerName: "Commodity Type",
    flex: 1,
    minWidth: 200,
  },
  {
    field: "storeSoh",
    headerName: "Store SOH",
    flex: 1,
    minWidth: 150,
    type: "number",
    colorCodeStatusCol: "CalculatedStatus",
  },
  { field: "amc", headerName: "AMC", flex: 1, minWidth: 150, type: "number" },
  { field: "mos", headerName: "MOS", flex: 1, minWidth: 150, type: "number" },
  {
    field: "receivedQuantity",
    headerName: "Received",
    flex: 1,
    minWidth: 150,
    type: "number",
  },
  {
    field: "issuedQuantity",
    headerName: "Issued",
    flex: 1,
    minWidth: 150,
    type: "number",
  },
  {
    field: "damagedQuantity",
    headerName: "Damaged",
    flex: 1,
    minWidth: 150,
    type: "number",
  },
  {
    field: "expiredQuantity",
    headerName: "Expired",
    flex: 1,
    minWidth: 150,
    type: "number",
  },
];

export const NEAR_EXPIRY_COLUMNS = [
  {
    field: "Item",
    headerName: "Item",
    flex: 2.5,
    minWidth: 300,
  },
  {
    field: "CommodityType",
    headerName: "Commodity Type",
    flex: 1,
    minWidth: 150,
  },
  {
    field: "Unit",
    headerName: "Unit",
    flex: 1,
    minWidth: 75,
  },
  {
    field: "Mode",
    headerName: "Mode",
    flex: 1,
    minWidth: 50,
  },
  {
    field: "Environment",
    headerName: "Hub",
    flex: 1,
    minWidth: 100,
  },
  {
    field: "SOH",
    headerName: "SOH",
    flex: 1,
    minWidth: md_2,
    type: "number",
  },
  {
    field: "SOHAmount",
    headerName: "SOH Amount",
    flex: 1,
    minWidth: md_2,
    type: "number",
  },
  {
    field: "ExpiryDate",
    headerName: "Expiry Date",
    flex: 1,
    minWidth: 150,
    type: "date",
    valueFormatter: (params) => {
      return DefaultDateFormatter(params?.value);
    },
  },
  {
    field: "AMC",
    headerName: "AMC",
    flex: 1,
    minWidth: md_2,
    type: "number",
  },
  {
    field: "MOS",
    headerName: "MOS",
    flex: 1,
    minWidth: md_2,
    type: "number",
  },
  {
    field: "PotentialExpiry",
    headerName: "Potential Expiry",
    flex: 1,
    minWidth: md_2,
    type: "number",
  },
  {
    field: "PotentialExpiryAmount",
    headerName: "Potential Expiry Amount",
    flex: 1,
    minWidth: md_2,
    type: "number",
  }
];

export const KPI_MODAL_COLUMNS = [
  { field: "month", headerName: "Month", flex: 2.5, minWidth: 400 },
  { field: "value", headerName: "Value", flex: 1, minWidth: 150 },
];

export const MODAL_COLUMNS = [
  { field: "item", headerName: "Items", flex: 1, minWidth: 250 },
  {
    field: "price",
    headerName: "Price",
    flex: 1,
    minWidth: 150,
    valueFormatter: (params) => {
      if (params.value == null) {
        return "";
      }
      const valueFormatted = Number(params.value).toLocaleString();
      return `$ ${valueFormatted}`;
    },
  },
  { field: "Percentage", headerName: "Percentage", flex: 1, minWidth: 150 },
  { field: "product_type", headerName: "Product Type", flex: 1, minWidth: 150 },
];

export const STOCK_STATUS_DEFAULT_FILTER_PARAMS = {
  //EnvironmentID: 4,
  OrderBy: "FullItemName",
  IsPPL: true,
  IsTracer: true
};

export const PROCUREMENT_STATUS_DEFAULT_FILTER_PARAMS = { IsAggregate: true };

export const LEGEND_STOCK_STATUS = [
  { label: "Excess", color: "#3288bd", statusCol: "SS" },
  { label: "Normal", color: "#99d594" },
  { label: "Below Min", color: "#fee08b" },
  { label: "Below EOP", color: "#fc8d59" },
  { label: "Stocked Out", color: "#d53e4f" },
];

export const LEGEND_STOCK_STATUS_FACILITY = [
  { label: "Excess", color: "#3288bd", statusCol: "CalculatedStatus" },
  { label: "Normal", color: "#99d594" },
  { label: "Below Min", color: "#fee08b" },
  { label: "Below EOP", color: "#fc8d59" },
  { label: "Stocked Out", color: "#d53e4f" },
];

export const LEGEND_RECENTLY_SYNCED_FACILITIES = [
  { label: "Recent", color: "#3288bd", statusCol: "CalculatedStatus" },
  { label: "Normal", color: "#99d594" },
  { label: "Out Dated", color: "#d53e4f" }
];

export const CHART_CONFIG_STOCK_STATUS = {
  show_chart: true,
  chart_data_source_api: "/EHPMD_WebApi/EHPMD_StockStatusShare?",
  data_label_property: "StockStatus",
  data_value_property: "ItemCount",
  data_label_info: LEGEND_STOCK_STATUS,
  style: {
    position: "relative",
    width: "13rem",
    height: "13rem",
    background: "White"
  }
};

export const CHART_CONFIG_STOCK_STATUS_FACILITY = {
  show_chart: true,
  data_label_property: "label",
  data_value_property: "value",
  data_label_info: LEGEND_STOCK_STATUS_FACILITY,
  data_aggregation_property: "CalculatedStatus",
  style: {
    position: "relative",
    width: "13rem",
    height: "13rem",
    background: "White"
  }
};

export const LEGEND_PROCUREMENT_STATUS = [
  { label: "Fully Received", color: "#3288bd", statusCol: "Status" },
  { value: "Partially Received", label: "Partially Received", color: "#99d594" },
  { value: "On Shipment Process", label: "On Shipment", color: "#756b29" },
  { value: "On Tender Process", label: "On Tender", color: "#a39226" },
  { value: "On Request Process", label: "On Request", color: "#c2ac1f" },
  { value: "On PurchaseOrder Process", label: "On Purchase Order", color: "#fada07" }
];

export const CHART_CONFIG_PROCUREMENT_STATUS = {
  show_chart: true,
  data_label_property: "label",
  data_value_property: "value",
  data_label_info: LEGEND_PROCUREMENT_STATUS,
  data_aggregation_property: "CalculatedStatus",
  style: {
    position: "relative",
    width: "13rem",
    height: "13rem",
    background: "White"
  }
};

export const LEGEND_REGISTERED_PRODUCTS = [
  { label: "Approved", color: "#3288bd", statusCol: "registration_status" },
  { value: "Pending", label: "Pending", color: "#c2ac1f" },
  { value: "Rejected", label: "Rejected", color: "#d53e4f" }
];

export const CHART_CONFIG_REGISTERED_PRODUCTS = {
  show_chart: true,
  data_label_property: "label",
  data_value_property: "value",
  data_label_info: LEGEND_REGISTERED_PRODUCTS,
  data_aggregation_property: "registration_status",
  style: {
    position: "relative",
    width: "13rem",
    height: "13rem",
    background: "White"
  }
};

export const licencedFacilityColumns = [
  {
    field: "FacilityName",
    headerName: "Facility Name",
    flex: 2.5,
    minWidth: 200,
  },

  {
    field: "FacilityType",
    headerName: "Facility Type",
    flex: 1,
    minWidth: 200,
  },
  {
    field: "ProductType",
    headerName: "Product Type",
    flex: 1,
    minWidth: 200,
  },
  {
    field: "BranchOffice",
    headerName: "Branch Office",
    flex: 1,
    minWidth: 200,
  },
  {
    field: "ApplicationType",
    headerName: "Application Type",
    flex: 1,
    minWidth: 200,
  },
];

export const wastageRateColumn = [
  {
    field: "Facility",
    headerName: "Facility",
    flex: 2.5,
    minWidth: 200,
  },

  {
    field: "FacilityType",
    headerName: "Facility Type",
    flex: 1,
    minWidth: 200,
  },
  {
    field: "ReceivedQuantity",
    headerName: "Received Quantity",
    flex: 1,
    minWidth: 200,

    valueFormatter: (params) => {
      if (params.value == null) {
        return "";
      }
      const valueFormatted = Number(params.value).toLocaleString();
      return `${valueFormatted} Birr`;
    },
  },
  {
    field: "TotalReceivedCost",
    headerName: "Received Cost",
    flex: 1,
    minWidth: 200,

    valueFormatter: (params) => {
      if (params.value == null) {
        return "";
      }
      const valueFormatted = Number(params.value).toLocaleString();
      return `${valueFormatted} Birr`;
    },
  },
  {
    field: "Mode",
    headerName: "Mode",
    flex: 1,
    minWidth: 200,
  },
  {
    field: "WastageRate",
    headerName: "Wastage Rate",
    flex: 1,
    minWidth: 200,

    valueFormatter: (params) => {
      if (params.value == null) {
        return "";
      }
      const valueFormatted = Number(params.value);
      return `${valueFormatted} %`;
    },
  },
];
export const daguNearExpiryColumn = [
  {
    field: "ItemName",
    headerName: "Item Name",
    flex: 2.5,
    minWidth: 200,
  },

  {
    field: "Unit",
    headerName: "Unit",
    flex: 1,
    minWidth: 75,
  },
  {
    field: "NearExpiryAmount",
    headerName: "Near Expiry Amount",
    flex: 1,
    minWidth: 200,

    valueFormatter: (params) => {
      if (params.value == null) {
        return "";
      }
      const valueFormatted = Number(params.value).toLocaleString();
      return `${valueFormatted} Birr`;
    },
  },
  {
    field: "NearExpiryQuantity",
    headerName: "Near Expiry Quantity",
    flex: 1,
    minWidth: 200,

    valueFormatter: (params) => {
      if (params.value == null) {
        return "";
      }
      const valueFormatted = Number(params.value).toLocaleString();
      return `${valueFormatted}`;
    },
  },
  {
    field: "Mode",
    headerName: "Mode",
    flex: 1,
    minWidth: 200,
  },
  {
    field: "ExpiryDate",
    headerName: "Expiry Date",
    flex: 1,
    minWidth: 200,
    type: "date",
    valueFormatter: (params) => {
      return DefaultDateFormatter(params?.value);
    },
  },
];

export const registeredProductsColumns = [
  { field: "hscode", headerName: "HS Code", flex: 1, minWidth: 150 },
  { field: "item", headerName: "Brand Name", flex: 1, minWidth: 150 },
  { field: "generic_name", headerName: "Generic Name", flex: 1, minWidth: 150 },
  { field: "presentation", headerName: "Presentation", flex: 1, minWidth: 200 },
  { field: "product_type", headerName: "Product Type", flex: 1, minWidth: 200 },
  {
    field: "manufacturer_name",
    headerName: "Manufacturer",
    flex: 1,
    minWidth: 200,
  },
  { field: "agent_name", headerName: "Agent", flex: 1, minWidth: 200 },
  {
    field: "supplier_name",
    headerName: "Supplier",
    flex: 1,
    minWidth: 200,
  },
  {
    field: "registration_date",
    headerName: "Registration Date",
    flex: 1,
    minWidth: 200,
    valueFormatter: (params) => moment(params?.value).format("DD/MM/YYYY"),
  },
  {
    field: "expiry_date",
    headerName: "Expiry Date",
    flex: 1,
    minWidth: 200,
    valueFormatter: (params) => moment(params?.value).format("DD/MM/YYYY"),
  },
];

export const importedProductsColumns = [
  { 
    field: "application_number", 
    headerName: "Application No", 
    flex: 1, 
    minWidth: 150 
  },
  { 
    field: "full_item_name", 
    headerName: "Item Name",  
    description: "full_item_name",
    flex: 1, 
    minWidth: 250 
  },
  { 
    field: "pack", 
    headerName: "Unit",  
    description: "pack", 
    flex: 1, 
    minWidth: 75 
  },
  {
    field: "ownership_type",
    headerName: "Ownership Type",
    flex: 1,
    minWidth: 125
  },
  {
    field: "invoice_number",
    headerName: "Invoice No",  
    description: "invoice_number",
    flex: 1,
    minWidth: 150,
    type: "number",
  },
  {
    field: "manufacturer",
    headerName: "Manufacturer",  
    description: "manufacturer",
    flex: 1,
    minWidth: 200,
  },
  { 
    field: "agent_name", 
    headerName: "Agent", 
    flex: 1, 
    minWidth: 200 
  },
  { 
    field: "agent_country", 
    headerName: "Agent Country", 
    flex: 1, 
    minWidth: 100 },
  {
    field: "supplier",
    headerName: "Supplier",  
    description: "supplier",
    flex: 1,
    minWidth: 250,
  },
  {
    field: "approval_date",
    headerName: "Approval Date",
    flex: 1,
    minWidth: 120,
    valueFormatter: (params) => moment(params?.value).format("MMM DD, YYYY"),
  },
  {
    field: "unit_price",
    headerName: "Unit Price",
    flex: 1,
    minWidth: 100,
    type: "number",
  },
  {
    field: "quantity",
    headerName: "Quantity",
    flex: 1,
    minWidth: 100,
    type: "number",
  },
  {
    field: "total_amount",
    headerName: "Total Amount",
    flex: 1,
    minWidth: 100,
    type: "number",
  },
  {
    field: "currency",
    headerName: "Currency",
    flex: 1,
    minWidth: 100,
    type: "number",
  }
];

export const PO_RECEIVES_COLUMNS = [
  { field: "Item", headerName: "Item", flex: 1, minWidth: 400 },
  {
    field: "CommodityType",
    headerName: "Commodity Type",
    flex: 2.5,
    minWidth: 150,
  },
  { field: "Unit", headerName: "Unit", flex: 1, minWidth: 75 },
  {
    field: "ReceivedQuantity",
    headerName: "Received Quantity",
    flex: 1,
    minWidth: 150,
    type: "number",
  },
  {
    field: "ReceivedAmount",
    headerName: "Received Amount",
    flex: 1,
    minWidth: 150,
    type: "number",
    valueFormatter: (param) => {
      return DefaultAmountFormatter(param.value);
    },
  },
  {
    field: "GRNF",
    headerName: "GRNF",
    flex: 1,
    minWidth: 100,
    type: "number",
  },
  {
    field: "GRV",
    headerName: "GRV",
    flex: 1,
    minWidth: 100,
    type: "number",
  },
  { field: "InvoiceNumber", headerName: "Invoice No", flex: 1, minWidth: 200 },
  { field: "PurchaseOrderNumber", headerName: "PO No", flex: 1, minWidth: 150 },
  {
    field: "ReceivedDate",
    headerName: "Received Date",
    flex: 1,
    minWidth: 200,
    type: "date",
    valueFormatter: (params) => {
      return DefaultDateFormatter(params?.value);
    },
  },
  { field: "Supplier", headerName: "Supplier", flex: 1, minWidth: 250 },
  { field: "Manufacturer", headerName: "Manufacturer", flex: 1, minWidth: 250 },
];

export const WASTAGE_RATE_RAW_COLUMNS = [
  { field: "item", headerName: "Item", flex: 1, minWidth: 400 },
  {
    field: "commodityType",
    headerName: "Commodity Type",
    flex: 2.5,
    minWidth: 150,
    filterParam: "CommodityTypeID"
  },
  { 
    field: "environment", 
    headerName: "Environment", 
    flex: 1, 
    minWidth: 150,
    filterParam: "EnvironmentID"
  },
  { field: "monthName", headerName: "Month", flex: 1, minWidth: 100 },
  { field: "calendarYear", headerName: "Year", flex: 1, minWidth: 100 },
  { field: "mode", headerName: "Mode", flex: 1, minWidth: 150 },
  { field: "program", headerName: "Program", flex: 1, minWidth: 150 },
  {
    field: "expired",
    headerName: "Expired",
    flex: 1,
    minWidth: 100,
    type: "number",
  },
  {
    field: "amountExpired",
    headerName: "Expired Amount",
    flex: 1,
    minWidth: 150,
    type: "number",
    valueFormatter: (param) => {
      return DefaultAmountFormatter(param.value);
    },
  },
  {
    field: "damaged",
    headerName: "Damaged",
    flex: 1,
    minWidth: 100,
    type: "number",
  },
  {
    field: "amountDamaged",
    headerName: "Damaged Amount",
    flex: 1,
    minWidth: 150,
    type: "number",
    valueFormatter: (param) => {
      return DefaultAmountFormatter(param.value);
    },
  },
];

export const PRODUCT_AVAILABILITY_RAW_COLUMNS = [
  { field: "item", headerName: "Item", flex: 1, minWidth: 400 },
  {
    field: "commodityType",
    headerName: "Commodity Type",
    flex: 2.5,
    minWidth: 150,
    filterParam: "CommodityTypeID"
  },
  { 
    field: "environment", 
    headerName: "Environment", 
    flex: 1, 
    minWidth: 150,
    filterParam: "EnvironmentID"
  },
  { field: "monthName", headerName: "Month", flex: 1, minWidth: 100 },
  { field: "calendarYear", headerName: "Year", flex: 1, minWidth: 100 },
  { field: "mode", headerName: "Mode", flex: 1, minWidth: 150 },
  { field: "program", headerName: "Program", flex: 1, minWidth: 150 },
  {
    field: "expired",
    headerName: "Expired",
    flex: 1,
    minWidth: 100,
    type: "number",
  },
  {
    field: "amountExpired",
    headerName: "Expired Amount",
    flex: 1,
    minWidth: 150,
    type: "number",
    valueFormatter: (param) => {
      return DefaultAmountFormatter(param.value);
    },
  },
  {
    field: "damaged",
    headerName: "Damaged",
    flex: 1,
    minWidth: 100,
    type: "number",
  },
  {
    field: "amountDamaged",
    headerName: "Damaged Amount",
    flex: 1,
    minWidth: 150,
    type: "number",
    valueFormatter: (param) => {
      return DefaultAmountFormatter(param.value);
    },
  },
];

export const MEDICAL_DEVICES_STATUS_COLUMNS = [
  { 
    field: "sn", 
    headerName: "SN", 
    flex: 1, 
    width: sm 
  },
  { 
    field: "name", 
    headerName: "Facility", 
    flex: 1, 
    minWidth: 200
  },
  { 
    field: "woreda", 
    headerName: "Woreda",
    flex: 1, 
    minWidth: 150 
  },
  { 
    field: "mdmc", 
    headerName: "MDMC",
    flex: 1, 
    minWidth: md,
    description: "MDMC functionality"
  },
  { 
    field: "memis", 
    headerName: "MEMIS",
    flex: 1, 
    minWidth: md,
    description: "MEMIS functionality"
  },
  { 
    field: "maintenance", 
    headerName: "Maintenance",
    flex: 1, 
    minWidth: md,
    description: "Availability of maintenance workshop"
  },
  { 
    field: "preventive", 
    headerName: "Preventive",
    flex: 1, 
    minWidth: md,
    description: "Preventive maintenance practice"
  },
  { 
    field: "functionality", 
    headerName: "Functionality",
    flex: 1, 
    minWidth: md,
    description: "Functionality Rate"
  },
  { 
    field: "installation", 
    headerName: "Installation",
    flex: 1, 
    minWidth: md,
    description: "Installation Rate"
  },
  { 
    field: "workOrderRate", 
    headerName: "Work Order",
    flex: 1, 
    minWidth: md,
    description: "Work Order Rate"
  }
];

export const MEDICAL_DEVICES_STATUS_FUNCTIONALITY_COLUMNS = [
  { 
    field: "facility", 
    headerName: "Facility", 
    flex: 1, 
    minWidth: 300
  },
  { 
    field: "parent", 
    headerName: "Parent",
    flex: 1, 
    minWidth: 250
  },
  { 
    field: "count", 
    headerName: "Passed",
    type: "number", 
    flex: 1, 
    minWidth: 50
  },
  { 
    field: "total", 
    headerName: "Total",
    type: "number", 
    flex: 1, 
    minWidth: 50
  },
  { 
    field: "percentage", 
    headerName: "Percentage",
    flex: 1, 
    minWidth: md,
    description: "Functionality Rate",
    valueFormatter: (param) => {
      return PercentFormatter(param.value);
    }
  }
]

export const registeredProducts2Columns = [
  { 
    field: "registration_number", 
    headerName: "Registration No", 
    flex: 1, 
    minWidth: 200
  },
  { 
    field: "full_item_name", 
    headerName: "Item Name",  
    description: "full_item_name",
    flex: 1, 
    minWidth: 250 
  },
  { 
    field: "pack", 
    headerName: "Unit",  
    description: "pack", 
    flex: 1, 
    minWidth: 125
  },
  {
    field: "registration_status",
    headerName: "Status",  
    description: "Registration Status",
    flex: 1,
    minWidth: 100,
  },
  {
    field: "product_category",
    headerName: "Category",  
    description: "Product Category",
    flex: 1,
    minWidth: 150,
  },
  {
    field: "applicant",
    headerName: "Applicant",
    flex: 1,
    minWidth: 200
  },
  { 
    field: "agent_country", 
    headerName: "Agent Country", 
    flex: 1, 
    minWidth: 100 
  },
  {
    field: "supplier_name",
    headerName: "Supplier",  
    description: "supplier",
    flex: 1,
    minWidth: 250,
  },
  {
    field: "manufacturer_name",
    headerName: "Manufacturer",  
    description: "Manufacturer",
    flex: 1,
    minWidth: 250,
  },
  {
    field: "registration_date",
    headerName: "Registration Date",
    flex: 1,
    minWidth: 120,
    valueFormatter: (params) => moment(params?.value).format("MMM DD, YYYY"),
  },
  {
    field: "expiry_date",
    headerName: "Expiry Date",
    flex: 1,
    minWidth: 120,
    valueFormatter: (params) => moment(params?.value).format("MMM DD, YYYY"),
  }
];

export const GetMemisStausColumns = function (indicatorKey) {
  let columns = MEDICAL_DEVICES_STATUS_FUNCTIONALITY_COLUMNS;
  if(MemisIndicators[indicatorKey]){
    let foundObject = columns.find(obj => obj.field === "count");
    foundObject.headerName = MemisIndicators[indicatorKey].CountColumn;
  }

  return columns;
}

const MemisIndicators = {
  Functionality: {
    CountColumn: "Functional"
  },
  Installation: {
    CountColumn: "Installed"
  }
}

const CUSTOM_DATA_GRID_CONSTANTS = [STOCK_STATUS_COLUMNS];

export default CUSTOM_DATA_GRID_CONSTANTS;
